import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'gatsby-plugin-intl';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Block from '../components/block/Block';
import Article from '../components/article/Article';
import Container from '../components/block/Container';

const audioPlayers = {};

const registerAudioPlayer = ({ id, playerRef }) => {
  audioPlayers[id] = playerRef;
};

const stopAudioPlayers = ({ id }) => {
  Object.keys(audioPlayers).forEach((key) => {
    if (key !== id) {
      audioPlayers[key].stop();
    }
  });
};

const IndexPage = ({ data, intl: { locale, formatMessage } }) => {
  const posts = data[locale].edges;
  return (
    <Layout>
      <SEO title="Cyril Planchon" />
      <Container>
        <Block
          title={formatMessage({ id: 'menu@activities' })}
          style={{ maxWidth: 720 }}
        >
          {
            posts.map(({ node }) => (
              <Article
                {...node}
                registerAudioPlayer={registerAudioPlayer}
                stopAudioPlayers={stopAudioPlayers}
              />
            ))
          }
        </Block>
      </Container>
    </Layout>
  );
};

IndexPage.propTypes = {
  intl: PropTypes.shape({
    locale: PropTypes.string,
    formatMessage: PropTypes.func,
  }).isRequired,
  data: PropTypes.shape({
    allContentfulBlogPost: PropTypes.shape({
      edges: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    fr: PropTypes.shape({
      edges: PropTypes.shape({}),
    }),
  }).isRequired,
};

export default injectIntl(IndexPage);

export const query = graphql`
  query IndexAllContentQuery {
    en: allContentfulBlogPost(filter: {node_locale: {eq: "en-US"}}, sort: {fields: date, order: DESC}) {
      edges {
        node {
          id
          slug
          title
          date
          body {
            json
          }
          playlist {
            id
            title
            track {
              file {
                fileName
                url
              }
            }
            duration
          }
          image {
            image {
              id
              file {
                url
                fileName
              }
            }
            portrait
          }
        }
      }
    },
    fr: allContentfulBlogPost(filter: {node_locale: {eq: "fr"}}, sort: {fields: date, order: DESC}) {
      edges {
        node {
          id
          slug
          title
          date
          body {
            json
          }
          playlist {
            id
            title
            track {
              file {
                fileName
                url
              }
            }
            duration
          }
          image {
            image {
              id
              file {
                url
                fileName
              }
            }
            portrait
          }
        }
      }
    },
  }
`;
